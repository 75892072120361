module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://48f5304f102643919ee226536ec9baa9@o1192228.ingest.sentry.io/6375461","environment":"UN BIODIVERSITY LAB","sampleRate":1,"ignoreErrors":["@math.gl/web-mercator: assertion failed","Fetch is aborted","NS_ERROR_FILE_NO_DEVICE_SPACE","ResizeObserver loop limit exceeded","ResizeObserver loop completed with undelivered notifications."]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-LFTMH83825"],"gtagConfig":{},"pluginConfig":{"head":true,"respectDNT":true,"exclude":[]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
